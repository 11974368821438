'use strict';

(function (angular) {

    VariantsPickerController.$inject = ['$scope', '$timeout', 'productService'];
    angular
        .module('jwsdw.picker')
        .controller('VariantsPickerController', VariantsPickerController);

    /**
     * @class jwsdwBasket.VariantsPickerController
     * @description Handles the request for getting variant information and showing the respective picker
     * @param {Object} $scope current scope
     * @param {Object} $timeout timeout service provided through angular
     * @param {Object} productService service to provide product information (used for creation of variants item)
     * @returns {Object} service object that returns public methods
     */
    function VariantsPickerController($scope, $timeout, productService) {
        var vm = this;

        vm.productService = productService;

        vm.variants = null;
        vm.variationDataReady = false;
        vm.colorNames = {};
        vm.selectedSize = null;
        vm.selectedColor = null;

        vm.hasSizes = hasSizes;
        vm.colorName = colorName;
        vm.getFirstVariantByColorId = getFirstVariantByColorId;
        vm.getVariantById = getVariantById;
        vm.getQuantity = getQuantity;
        vm.updateProduct = updateProduct;

        //listener for broadcasted event 'openPicker' fired in the basket controller
        window.jwsdwMediator.subscribe('openPicker', function(type, data) {
            if (type === 'variationPicker') {
                vm.dataService = data.dataService;
                activate(data.product);
            }
        });

        /* Listening to picker close event to reset the picker interface */
        window.jwsdwMediator.subscribe('afterPickerClosed', function(type) {
            if (type === 'variationPicker') {
                vm.variationDataReady = false;
            }
        });

        window.jwsdwMediator.subscribe('changeColor', function(colorId) {
            if (vm.selectedSize && colorId) {
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(vm.selectedSize.id, colorId);
                    });
                });
            }
        });
        window.jwsdwMediator.subscribe('changeSize', function(sizeId) {
            if (vm.selectedColor && sizeId) {
                $timeout(function() {
                    $scope.$apply(function() {
                        _changeProduct(sizeId, vm.selectedColor.id);
                    });
                });
            }
        });

        /**
         * @description Method to initialize the variants, get all variant product information and show the picker.
         * @param {Object} product product for which variants have to be loaded
         * @param {String} product.basketItemId basket item Id
         * @param {String} product.id product Id
         * @param {String} product.size size
         * @param {String} product.stock amount in stock
         * @param {String} product.colorId color Id
         * @returns {void}
         */
        function activate(product) {
            vm.basketItemId = product.basketItemId;
            vm.initialProductId = product.id;
            vm.variants = [];

            productService.getVariants(product).then(function (variants) {
                vm.variants = variants;
                vm.currentProduct = product;

                vm.variants.concat(vm.currentProduct).forEach(function(variant) {
                    variant.sizes = variant.sizes.map(function(size) {
                        size.id = size.id.replace('B', '');
                        size.value = size.value.replace('B', '');
                        size.display = true;
                        return size;
                    });

                    if (variant.size) {
                        variant.size = variant.size.replace('B', '');
                    }
                    variant.sizeMap = productService.getSizeMap(variant.sizes, variant);
                });

                vm.selectedSize = {
                    'id': product.size,
                    'available': product.stock > 0
                };
                vm.selectedColor = {
                    'id': product.colorId,
                    'available': product.stock > 0,
                    'name': product.color
                };
                vm.variationDataReady = true;
            });
        }

        /**
         * @description Method to check if product has sizes
         * @returns {Boolean} true if product has sizes
         * @memberOf jwsdwProductDetail.dataService
         */
        function hasSizes() {
            return Object.keys(vm.currentProduct.sizeMap.sizes).some(function(type) {
                return vm.currentProduct.sizeMap.sizes[type].length > 0;
            });
        }

        /**
         * @description Method broadcast to the basket that the product has been updated
         * @returns {void}
         */
        function updateProduct() {
            window.jwsdwMediator.publish('updateBasketProduct', {
                'itemId': vm.basketItemId,
                'initialProductId': vm.initialProductId,
                'variantId': vm.currentProduct.id,
                'quantity': getQuantity(),
                'product': vm.currentProduct
            });
        }

        /**
         * @description Method to change product based on color and size IDs
         * @param {String} sizeId size ID
         * @param {String} colorId color ID
         * @returns {void}
         * @private
         */
        function _changeProduct(sizeId, colorId) {
            vm.currentProduct = vm.variants.find(function(variation) {
                return variation.size === sizeId && variation.colorId === colorId;
            }) || vm.currentProduct;
        }

        /**
         * @description Method to get the name of a color by the color id and save it to the color names object in the view model.
         * @param {String} colorId the id of the color for which the name should be retrieved
         * @returns {String} color name
         */
        function colorName(colorId) {
            var variant,
                color = '';

            if (vm.colorNames.hasOwnProperty(colorId)) {
                color = vm.colorNames[colorId];
            } else {
                variant = getFirstVariantByColorId(colorId);
                if (variant) {
                    color = variant.color;
                    vm.colorNames[colorId] = color;
                }
            }

            return color;
        }

        /**
         * @description Method to get the first variant for a color id as representative for the group.
         * @param {String} colorId the id of the color for which the first variant should be retrieved
         * @returns {Object} variant product object
         */
        function getFirstVariantByColorId(colorId) {
            return vm.variants.find(function (variant) {
                return variant.colorId === colorId;
            });
        }

        /**
         * @description Method to get a product variant by its id.
         * @param {String} id id of the product variant that should be retrieved
         * @returns {Object} variant product object
         */
        function getVariantById(id) {
            return vm.variants.find(function (variant) {
                return variant.id === id;
            });
        }

        /**
         * @description Method to get the quantity of the variant currently in the basket and check if the currently selected variant
         * is available in the same quantity. If not the maximum stock of the variant is returned.
         * @returns {Number} basketQuantity or stock
         */
        function getQuantity() {
            var basketQuantity = vm.dataService.productQuantity(vm.basketItemId),
                stock = vm.currentProduct.stock;
            return basketQuantity > stock ? stock : basketQuantity;
        }
    }
}(angular));
