'use strict';

(function (angular) {
    angular
        .module('jwsdw.product')
        .constant('IMAGE_BASE_URL', window.jwsdwSettings.productGetImageBaseUrl)
        .constant('SIZEGUIDE', window.jwsdwSizeGuide)
        .constant('SIZE_SORTING_ORDER', [
            'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', '3XL',
            'regular-XS', 'regular-S', 'regular-M', 'regular-L', 'regular-XL', 'regular-XXL', 'regular-XXXL', 'regular-3XL',
            'short-XS', 'short-S', 'short-M', 'short-L', 'short-XL', 'short-XXL', 'short-XXXL', 'short-3XL',
            'tall-XS', 'tall-S', 'tall-M', 'tall-L', 'tall-XL', 'tall-XXL', 'tall-XXXL', 'tall-3XL'
        ]);
}(angular));