'use strict';

(function (angular) {

    ColorSelectController.$inject = ['$scope'];
    angular
        .module('jwsdw.product')
        .directive('jwsdwColorSelect', jwsdwColorSelect);

    /**
     * @class jwsdwProduct.jwsdwColorSelect
     * @description Color selection form
     * @returns {Object} directive object
     */
    function jwsdwColorSelect() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'colorSelect.html',
            'replace': true,
            'scope': {
                'colors': '=jwsdwColorSelect',
                'background': '=jwsdwBackground',
                'hideAvailability': '=jwsdwHideAvailability',
                'selectedColor': '=',
                'initialColor': '='
            },
            'controller': ColorSelectController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwProduct.jwsdwColorSelect.ColorSelectController
     * @description Controller for color select input
     * @param {Object} $scope current scope
     * @returns {void}
     */
    function ColorSelectController($scope) {
        var vm = this;

        /**
         * @description Method to run the initialization of the color select directive
         * @returns {void}
         * @memberOf jwsdwProduct.jwsdwColorSelect
         */
        this.$onInit = function () {
            var currentColor = vm.selectedColor ? vm.colors.find(function(c) {
                return vm.selectedColor.name === c.name;
            }) : null;

            if (!currentColor || (!currentColor.orderable && currentColor.id !== vm.initialColor.id)) {
                vm.selectedColor = vm.colors.find(function(c) {
                    return c.orderable;
                });
            }

            $scope.$watch('vm.selectedColor', function(color) {
                if (color) {
                    window.jwsdwMediator.publish('changeColor', color.id);
                }
            });
        };
    }
}(angular));