'use strict';

(function (angular) {

    StickyAddToCartController.$inject = ['$window', '$scope', '$timeout'];
    angular
        .module('jwsdw.stickyAddToCart')
        .controller('StickyAddToCartController', StickyAddToCartController);

    /**
     * @class jwsdwStickyAddToCart.StickyAddToCartController
     * @description Initializes the breadcrumbs functionality
     * @param {Object} $window current window wrapped as jquery element
     * @param {Object} $scope current scope
     * @param {Object} $timeout angular timeout function
     * @returns {void}
     */
    function StickyAddToCartController($window, $scope, $timeout) {
        var vm = this;

        vm.addingProductToBasket = false;
        vm.selectedColor = null;

        vm.addToBasket = addToBasket;
        vm.scrollToSizes = scrollToSizes;
        vm.openNotifyMe = openNotifyMe;

        /**
         * Activate function
         * @returns {void}
         */
        this.$onInit = function () {
            $window.jwsdwMediator.subscribe('updateStickyAddToCart', function(data) {
                $timeout(function () {
                    $scope.$apply(function () {
                        vm.addingProductToBasket = data.addingProductToBasket;
                        vm.isGiftcard = data.isGiftcard;
                        vm.giftcardpricing = data.giftcardpricing;
                        vm.selectedSize = data.selectedSize;
                        vm.dataService = data.dataService;
                        vm.showStickyCartButton = data.showStickyCartButton;
                        if (!vm.selectedColor) {
                            vm.selectedColor = data.dataService.initialColor();
                        }
                    });
                });
            });

            $window.jwsdwMediator.subscribe('changeColor', function(color) {
                if (vm.bonusProductSelectionPickerVisible) {
                    return;
                }
                $timeout(function() {
                    $scope.$apply(function() {
                        vm.selectedColor = vm.dataService.colorById(color);
                    });
                });
            });
        };

        /**
         * @description Method to publish an add to basket event
         * @returns {void}
         * @memberOf jwsdwStickyAddToCart.StickyAddToCartController
         */
        function addToBasket() {
            $window.jwsdwMediator.publish('addToBasket');
        }

        /**
         * @description Method to scroll to size select
         * @returns {void}
         * @memberOf jwsdwProductDetail.ProductDetailController
         */
        function scrollToSizes() {
            $window.jwsdwMediator.publish('scrollToSizes');
        }

        /**
         * @description Method to open notifyme
         * @returns {void}
         */
        function openNotifyMe () {
            if (!vm.selectedSize) {
                vm.displayNoSizeSelectedWarning = true;
                return;
            }
            $window.jwsdwMediator.publish('openPicker', 'notifyMePicker', {
                'width': 1,
                'productID': vm.dataService.productId(vm.selectedSize.id, vm.selectedColor.id),
                'productName': vm.dataService.productName(vm.selectedSize.id, vm.selectedColor.id),
                'productSize': vm.selectedSize.name,
                'productColor': vm.selectedColor.name,
                'image': vm.dataService.imageUrl(vm.dataService.productId(vm.selectedSize.id, vm.selectedColor.id), 0, vm.dataService.transforms[vm.viewport], 'bone5'),
                'isVisible': true,
                'level': 2
            });

            // Script Notify Me Button Click (open)
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'ce.misc',
                'eventCategory': 'MISC',
                'eventAction': 'Notify Me',
                'eventLabel': 'open',
                'eventValue': undefined,
                'nonInteraction': false,
                'selection': undefined
            });
            return true;
        }
    }
}(angular));