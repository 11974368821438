'use strict';

(function (angular) {

    SizeSelectController.$inject = ['$scope'];
    angular
        .module('jwsdw.product')
        .directive('jwsdwSizeSelect', jwsdwSizeSelect);

    /**
     * @class jwsdwProduct.jwsdwSizeSelect
     * @description Size selection form
     * @returns {Object} directive
     */
    function jwsdwSizeSelect() {
        var directive = {
            'restrict': 'A',
            'templateUrl': 'sizeSelect.html',
            'replace': true,
            'scope': {
                'sizes': '=jwsdwSizeSelect',
                'selectedSize': '='
            },
            'controller': SizeSelectController,
            'controllerAs': 'vm',
            'bindToController': true
        };

        return directive;
    }

    /* @ngInject */
    /**
     * @class jwsdwProduct.jwsdwSizeSelect.SizeSelectController
     * @description Controller for size selection input
     * @constructor
     * @param {Object} $scope current scope
     */
    function SizeSelectController($scope) {
        var vm = this;

        /**
         * @description Method to run the initialization of the product detail module
         * @returns {void}
         */
        this.$onInit = function () {
            if (vm.selectedSize && vm.selectedSize.id) {
                vm.selectedSizeId = vm.selectedSize.id;

                $scope.$watch('vm.selectedSizeId', function (selectedSizeId) {
                    if (selectedSizeId && selectedSizeId !== vm.selectedSize.id) {
                        vm.selectedSize = vm.sizes.find(function (size) {
                            return size.id === selectedSizeId;
                        });
                    }
                });

                $scope.$watch('vm.selectedSize', function (size) {
                    if (size && size.id !== vm.selectedSizeId) {
                        vm.selectedSizeId = size.id;
                    }
                    window.jwsdwMediator.publish('changeSize', vm.selectedSizeId);
                });
            }
        };
    }
}(angular));