'use strict';

(function (angular) {

    ocapiBasketService.$inject = ['$q', '$http', '$window', '$log', 'authService'];
    angular
        .module('jwsdw.ocapi')
        .factory('ocapiBasketService', ocapiBasketService);

    /**
     * @class jwsdwOCAPI.ocapiBasketService
     * @description OCAPI basket service that provides access to basket ocapi resource.
     * @param {Object} $q promise service that provides promise functionality
     * @param {Object} $http http service that provides an API for ajax calls
     * @param {Object} $window window service that provides access to the window object
     * @param {Object} $log log service that provides logging in angluar
     * @param {Object} authService ocapi auth service that provides access to authentication to the ocapi.
     * @returns {Object} service object that returns public methods
     */
    function ocapiBasketService($q, $http, $window, $log, authService) {
        var ocapiVersion = 'v17_2',
            service;

        service = {
            'add': add,
            'addManyWihPipeline': addManyWihPipeline,
            'addViaPipeline': addViaPipeline,
            'prepareCheckout': prepareCheckout
        };

        return service;

        /**
         * @description Method to check the product data and add default data then sends the values to the addProductsViaPipeline method.
         * @param {String[]} productIds Array of product ids
         * @param {Object} [productData] data that belongs to the products that are added
         * @returns {Object} promise containing basket data (provided by the addProductsViaPipeline method)
         */
        function addManyWihPipeline(productIds, productData) {
            productData = productData || {'products': []};
            $window.jwsdwMediator.publish('openPicker', 'cartPicker', productData);

            let defer = $q.defer();
            _addProductSetViaPipeline(productIds).then(success, error);
            return defer.promise;
            /**
             * Function on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                productData = Object.assign(productData, response.data);
                productData.products = productData.products.map(function(product) {
                    product.price = product.productPriceWithVAT;
                    return product;
                });
                productData.addToCartSuccessful = true;
                $window.jwsdwMediator.publish('updatePicker', 'cartPicker', productData);
                window.jwsdwMediator.publish('updatePicker', 'expressCheckoutPicker', productData);
                $window.jwsdwMediator.publish('setCartCount', productData.totalItems);
                defer.resolve();
            }

            /**
             * Function on error
             * @param {Object} response response object
             * @returns {void}
             */
            function error(response) {
                defer.reject(response);
            }
        }

        /**
         * @description Method to add given products to the basket.
         * @param {String[]} productIds product ids
         * @returns {Promise} promise containing basket data
         */
        function add(productIds) {
            var defer = $q.defer(),
                basketId = $window.jwsdwSettings.basket.id;

            // get access token
            authService.getAccessToken().then(authSuccess, authError);

            return defer.promise;

            /**
             * Function on authentification success
             * @param {Object} response response object
             * @param {Object} response.accessToken access token
             * @returns {void}
             */
            function authSuccess(response) {
                // get basket id and basket etag
                _getBasket(basketId, response.accessToken).then(getBasketSuccess);

                /**
                 * Function onget basket success
                 * @param {Object} existingBasketData existing basket data
                 * @returns {void}
                 */
                function getBasketSuccess(existingBasketData) {
                    var etag = existingBasketData.headers().etag,
                        products = productIds.map(function(productId) {
                            return {
                                'product_id': productId,
                                'quantity': 1
                            };
                        });

                    _addProducts(products, response.accessToken, etag, basketId).then(function(basketData) {
                        $log.debug('jwsdw.ocapi: Basket request successful:', basketData);
                        defer.resolve(basketData.data);
                    });
                }
            }
        }

        /**
         * @description Method to check if auth error was an expired token error and trigger mediator to show a session error message.
         * @param {Object} errorData error data object returned with the response
         * @returns {void}
         */
        function authError(errorData) {
            if (errorData.hasOwnProperty('fault') && errorData.fault.type === 'ExpiredTokenException') {
                $window.jwsdwMediator.publish('sessionExpired');
            }
        }

        /**
         * @description Method to check the product data and add default data then sends the values to the addProductsViaPipeline method.
         * @param {String[]} productIds Array of product ids
         * @param {Object} [productData] data that belongs to the products that are added
         * @param {Boolean} isExpressCheckout true if it is an express buying process
         * @returns {Object} promise containing basket data (provided by the addProductsViaPipeline method)
         */
        function addViaPipeline(productIds, productData, isExpressCheckout) {
            productData = productData || {'products': []};

            if (isExpressCheckout) {
                $window.jwsdwMediator.publish('openPicker', 'expressCheckoutPicker', productData);
            } else {
                $window.jwsdwMediator.publish('openPicker', 'cartPicker', productData);
            }

            return addProductsViaPipeline(productIds, productData, isExpressCheckout);
        }

        /**
         * @description Method to add multiple products to basket via the Cart-addProduct pipeline/controller function.
         * @param {String[]} productIds Array of product ids
         * @param {Object} productData data that belongs to the products that are added
         * @param {Boolean} isExpressCheckout true if it is an express buying process
         * @returns {Object} promise
         * @private
         */
        function addProductsViaPipeline(productIds, productData, isExpressCheckout) {
            var defer = $q.defer();

            _addProductViaPipeline(productIds.pop(), productData, isExpressCheckout).then(success, error);

            return defer.promise;

            /**
             * Function on success
             * @param {Object} response response object
             * @returns {void}
             */
            function success(response) {
                var choiceOfBonusProductPicker;

                productData = Object.assign(productData, response.data);
                productData.products = productData.products.map(function(product) {
                    product.price = product.productPriceWithVAT;
                    return product;
                });

                if (productIds.length > 0) {
                    addProductsViaPipeline(productIds, productData);
                } else {
                    choiceOfBonusProductPicker = $window.sessionStorage.getItem('jwsdw-choiceOfBonusProductPicker');

                    // all products have been added, check for bonus discount line items and open cart picker
                    if (productData.hasOwnProperty('hasBonusDiscountLineItem') && productData.hasBonusDiscountLineItem && choiceOfBonusProductPicker !== 'hide') {
                        productData.level = 2;
                        $window.jwsdwMediator.publish('openPicker', 'bonusProductSelectionPicker', productData);
                    } else {
                        productData.addToCartSuccessful = true;
                        $window.jwsdwMediator.publish('updatePicker', 'cartPicker', productData);
                        window.jwsdwMediator.publish('updatePicker', 'expressCheckoutPicker', productData);
                        $window.jwsdwMediator.publish('setCartCount', productData.totalItems);
                    }

                    defer.resolve();
                }
            }

            /**
             * Function on error
             * @param {Object} response response object
             * @returns {void}
             */
            function error(response) {
                defer.reject(response);
            }
        }

        /**
         * @description Method to trigger the prepareCheckout function in the basket controller.
         * @returns {Object} Promise of the http call
         */
        function prepareCheckout() {
            $log.debug('jwsdw controller: Validate basket');
            return $http({
                'method': 'GET',
                'url': $window.app.urls.cartPrepareCheckout,
                'params': {
                    'format': 'ajax'
                }
            });
        }

        /**
         * OCAPI call to gets the basket with the given basket id.
         * @param  {string} basketId the id of the basket that is requested
         * @param  {string} accessToken the token from the authentication that allows to access the ocapi basket resource
         * @returns {Object} promise
         * @private
         */
        function _getBasket(basketId, accessToken) {
            $log.debug('jwsdw.ocapi: Get basket with basketId', basketId, 'and accessToken', accessToken);
            return $http({
                'method': 'GET',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/baskets/' + basketId,
                'headers': {
                    'Authorization': accessToken
                },
                'params': {
                    'client_id': $window.jwsdwSettings.clientId
                },
                'handleByCaller': true
            });
        }

        /**
         * OCAPI call to add multiple products to the basket.
         * @param {Object[]} products product_id and quantity for each product
         * @param {String} accessToken the token from the authentication that allows to access the ocapi basket resource
         * @param {String} etag The etag is used for the ocapi handshake
         * @param {String} basketId the id of the basket
         * @returns {Object} promise
         * @private
         */
        function _addProducts(products, accessToken, etag, basketId) {
            $log.debug('jwsdw.ocapi: Add products: ', products, '(accessToken:', accessToken, ', etag:', etag, ', basketId', basketId, ')');
            return $http({
                'method': 'POST',
                'url': $window.jwsdwSettings.ocapiBaseUrl + ocapiVersion + '/baskets/' + basketId + '/items',
                'headers': {
                    'Authorization': accessToken,
                    'If-Match': etag
                },
                'params': {
                    'client_id': $window.jwsdwSettings.clientId
                },
                'data': products
            });
        }

        /**
         * Adds a single product to the basket.
         * @param {String} productId id of the product that is added via Cart-addProduct controller function
         * @param {Object} productData data that belongs to the product (used to pass price value for gift cards etc.)
         * @param {Number} productData.price product price
         * @param {Boolean} isExpressCheckout true if it is an express buying process
         * @returns {Object} promise
         * @private
         */
        function _addProductViaPipeline(productId, productData, isExpressCheckout) {
            var data = {};

            data.productId = productId;
            data.price = productData.price;
            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            if (isExpressCheckout) {
                data.source = 'express';
            }

            return $http({
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                'transformRequest': function (obj) {
                    var str = [],
                        p;
                    for (p in obj) {
                        if (obj.hasOwnProperty(p)) {
                            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
                        }
                    }
                    return str.join('&');
                },
                'url': $window.jwsdwSettings.baseUrl + '/Cart-addProduct',
                'data': data
            });
        }
        /**
         * Adds a single product to the basket.
         * @param {String} productIds id of the product that is added via Cart-addProduct controller function
         * @returns {Object} promise
         * @private
         */
        function _addProductSetViaPipeline(productIds) {
            var data = {};

            data.childPids = productIds.join(',');
            data.childQtys = new Array(productIds.length).fill(1);
            data.childQtys = data.childQtys.join(',');
            data[$window.jwsdwSettings.csrfTokenName] = $window.jwsdwSettings.csrfTokenValue;

            return $http({
                'method': 'POST',
                'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                'transformRequest': function (obj) {
                    var str = [],
                        p;
                    for (p in obj) {
                        if (obj.hasOwnProperty(p)) {
                            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
                        }
                    }
                    return str.join('&');
                },
                'url': $window.jwsdwSettings.baseUrl + '/Cart-addProductSet',
                'data': data
            });
        }
    }
}(angular));
